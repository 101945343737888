<template>
    <div class="page-title">
      <div class="title">{{title}}</div>
      <div class="title-desc">
          <slot></slot>
      </div>
    </div>
</template>
<script>
export default {
    name:"PageTitle",
    props:{
        title:{
            type:String,
            default:"页面标题"
        }
    }
}
</script>
<style lang="less" scoped>
.page-title{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 5px 0px 15px;
    .title{
        padding-left: 6px;
        border-left: 6px solid #0ba882;
        font-weight: bold;
    }
    .title-desc{
        margin-left: 10px;
        font-size: 14px;
        color: #868686;
    }
}
</style>