import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';
import {resetRouter} from '../router/index.js';

//模块化引入
import design from './modules/design'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    actObj:JSON.parse(sessionStorage.getItem("actobj")),
    user:JSON.parse(sessionStorage.getItem("user")),
    menu:JSON.parse(sessionStorage.getItem("menu")),
  },
  mutations: {
    // commit("add")会调用mutations的add方法
    setActObj(state,val){
      state.actObj = val;
      sessionStorage.setItem("actobj",JSON.stringify(val));
    },
    setUser(state,val){
      state.user = val;
      sessionStorage.setItem("user",JSON.stringify(val));
    },
    setMenu(state,val){
      state.menu = val;
      sessionStorage.setItem("menu",JSON.stringify(val));
    },
  },
  actions: {
    // $store.dispatch("add")会调用actions的add方法
    initRoutes(){
      const layout = {
        path:'/setting',
        name:'SettingHome',
        component:()=>import('../views/_layout/settingIndex.vue'),
        meta:{title:'配置界面布局'},
      }
      router.addRoute(layout);
      let routers = [];
      genRouters(this.state.menu,routers);
      routers.forEach(i=>{
        router.addRoute("SettingHome",i)
      });
    },
    //登出之后清空前端缓存的所有数据
    resetAll({commit}){
      commit("setUser",{});
      commit("setMenu",[]);
      sessionStorage.removeItem("token");
      resetRouter();
    }
  },
  modules: {
    design
  }
});
/**
 * 递归遍历菜单树，获取所有菜单的路由地址。
 * @param {*} menus 
 * @param {*} routers 
 */
function genRouters(menus,routers=[]){
  if(menus&&menus.length!=0){
    menus.forEach(item => {
      if(item.children&&item.children.length){
        genRouters(item.children,routers);
      }
      if(item.menuUrl){
        const route = {
          path: item.menuUrl,
          name:item.id,
          component: () => import(`../views/${item.menuPath}.vue`),
          meta:{
            title:item.menuName
          }
        };
        routers.push(route);
      }
    });

    /**静态路由配置：添加到此处之后遍历加入大路由中 */
    const routeStatics = [{
      path: "/setting/case/add",
      name:"AddCase",
      component: () => import(`../views/case/addcase.vue`),
      meta:{
        title:"新增案件"
      }
    },{
      path: "/case/detail/index",
      name:"AddCase",
      component: () => import(`../views/case/detail/index.vue`),
      meta:{
        title:"案件详情"
      }
    }];
    routeStatics.forEach(item=>{
      routers.push(item);
    });
  }
}
