const txtObj = {
    uuid:Date.now(),
    type:'txt',
    value:'热烈<br>庆祝',
    top:'0px',
    left:'0px',
    color:'#ff0000',
    bgColor:'none',
    fontSize:"80px",
    fontFamily:"ceshi3",
    divWidth:"auto",
    divHeight:"auto"
};
const imgObj ={
    uuid:Date.now()+10,
    type:'img',
    // value:'http://bearcarimg.codebear.cn/b159e9d856d5bb82a73d92103674134817Ih6um8QgB3fmMUDdAtT4!gradual.show',
    value:"http://bearcarimg.codebear.cn/f67d9d38e280544cd9dbd3ee6c520b904MtiiViAgAH7HYywyZdNIG!gradual.show",
    top:0,
    left:0,
    ltR:0,
    rtR:0,
    lbR:0,
    rbR:0,
    divWidth:300,
    divHeight:"auto"
};
console.log(txtObj,imgObj);
const state = {
    elList:[],//页面元素新增组件根
    activeEl:{
        uuid:"",
        value:"",
    }
}

const getters={
    elList(state){
        return state.elList;
    },
    activeEl(state){
        return state.activeEl;
    }
}

const actions={
    initElList(store,val){
        store.state.elList = val;
    },
    setelList(store,val){
        console.log(1);
        store.state.elList.push(val);
        console.log(store.state.elList)
    },
    //页面组件删除
    rmElList(store,val){
        let index = store.state.elList.indexOf(val);
        store.state.elList.splice(index,1);
    },
    rmElListByuuid(store,uuid){
        const txtObj  = store.state.elList.find(item=> item.uuid === uuid);
        store.state.elList.splice(store.state.elList.indexOf(txtObj),1);
    },
    setActiveEl(store,val){
        if(val.top){store.state.activeEl.top = val.top;}
        if(val.left){store.state.activeEl.left = val.left;}
        if(val.divWidth){store.state.activeEl.divWidth = val.divWidth;}//只处理宽度，高度自动适应即可。
        if(val.divHeight){store.state.activeEl.divHeight = val.divHeight;}
    },
    reActiveSize(store,val){
        store.state.activeEl.divWidth = val.divWidth;
    },
    /**同步更新所有对象的状态 */
    updateAll(store,val){
        const activeVal  = store.state.elList.find(item=> item.uuid === val);
        store.state.activeEl = activeVal;
    },
    /**清除活动的元素 */
    clearAll(store){
        store.state.activeEl = {};
    }

}

export default{
    state,actions,getters
}