import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/icons' // svg 图标
import PageTitle from '@/components/bigconfig/pagetitle.vue';//全局页面标题组件


// http请求
import request from './request/request'
// 在原型上扩展,这样不用在每个页面都导入request
Vue.prototype.request = request;

//挂载全局的图片映射目录
// Vue.prototype.imgurl = "http://localhost:8882/elecase/img/";//开发环境
// Vue.prototype.imgurl = "http://47.118.35.64:8882/elecase/img/";//线上环境
// Vue.prototype.imgurl = "http://192.168.2.5:8882/elecase/img/";//线上环境
Vue.prototype.imgurl = "https://huilongweixiu.com/api/img/";//线上环境



//drag自定义指令
import directive from './utils/directives.js'
Vue.use(directive);

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.component('page-title',PageTitle);//配置页面中的页面标题组件注册。

store.dispatch("initRoutes");
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
