export default (Vue) => {
    Vue.directive("drag", {
        //1.指令绑定到元素上回立刻执行bind函数，只执行一次。另：亦可用inserted
        //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
        //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
        bind: function (el,binding) {
            let oDiv = el; // 当前元素
            // 禁止选择拖拽块上的文字
            document.onselectstart = function () {
                return false;
            };
            oDiv.onmousedown = function (e) {
                // 获取父元素dom
                let pbox = document.getElementById('page-design-container')
                console.log('parent-box', pbox.clientWidth, pbox.clientHeight)
                console.log('child-box', oDiv.clientWidth, oDiv.clientHeight)
                console.log('e:', e, oDiv.offsetLeft, oDiv.offsetTop)
                // 鼠标按下，计算当前元素距离可视区的距离
                let disX = e.clientX - oDiv.offsetLeft;
                let disY = e.clientY - oDiv.offsetTop;
                oDiv.setAttribute('ele-flag', false)// 给当前元素添加属性，用于元素状态的判断
                oDiv.setAttribute('draging-flag', true)
                document.onmousemove = function (e) {
                    // 通过事件委托，计算移动的距离
                    let l = e.clientX - disX;
                    let t = e.clientY - disY;
                    if (l < 0) {
                        l = 0
                    } else if (l > (pbox.clientWidth - oDiv.clientWidth)) {
                        l = pbox.clientWidth - oDiv.clientWidth
                    }
                    if (t < 0) {
                        t = 0
                    } else if (t > (pbox.clientHeight - oDiv.clientHeight)) {
                        t = pbox.clientHeight - oDiv.clientHeight
                    }
                    // 移动当前元素
                    oDiv.style.left = l + "px";
                    oDiv.style.top = t + "px";
                    binding.value({top:t,left:l,divWidth:oDiv.clientWidth,divHeight:oDiv.clientHeight});
                };
                document.onmouseup = function () {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
                // 防止黏连
                return false;
            };
        }
    })
}