import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**
 *  静态路由定义
 */
const routes = [
  {
    path: '/',
    name: 'Index',
    // component: () => import('@/views/_layout/settingIndex.vue')
    component: ()=> import('@/views/login.vue')
  },
  //配置管理部分
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('@/views/login.vue'),
    meta:{tilte:'登录页面'}
  },
  /**其它路由用户登录之后动态生成。 */
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

/**清空动态加载的路由数据 */
export function resetRouter(){
  console.log("开始清空数据")
  const newRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });
  router.matcher = newRouter.matcher; // reset router
}

/**路由监测 */
router.beforeEach((to,from,next)=>{
  console.log(to,from);
  next();
});

export default router
